<template>
  <div>
    <!-- lead-search -->
    <div
      class="lead-search-area"
      v-if="$storage.get('auth').user.user_type != 2"
    >
      <div class="row">
        <div class="col-sm-4 col-md-4 col-lg-4">
          <div class="lead-search">
            <div class="form-group">
              <label for="exampleFormControlSelect22">Agent</label>
              <select
                class="form-control"
                id="exampleFormControlSelect22"
                v-model="filterSearch.agent"
                v-on:change="updateData('agent')"
              >
                <option value="" disabled selected hidden>Select Agent</option>
                <option value="">All</option>
                <option
                  v-for="emp in allEmployees"
                  :key="emp.id"
                  :value="emp.id"
                >
                  {{ emp.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-sm-4 col-md-4 col-lg-4">
          <div class="lead-search">
            <div class="form-group">
              <label for="exampleFormControlSelect1">Teams</label>
              <select
                class="form-control"
                id="exampleFormControlSelect1"
                v-model="filterSearch.team"
                v-on:change="updateData('team')"
              >
                <option value="" disabled selected hidden>Select Team</option>
                <option value="">All</option>
                <option
                  v-for="team in allTeams"
                  :key="team.id"
                  :value="team.tm_id"
                >
                  {{ team.tm_name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-sm-4 col-md-4 col-lg-4">
          <div class="lead-search">
            <div class="form-group">
              <label for="exampleFormControlSelect1">Status</label>

              <select
                class="form-control"
                v-model="filterSearch.status"
                v-on:change="updateData('status')"
              >
                <option value="" disabled selected hidden>Select Status</option>
                <option value="">All</option>
                <option value="all">Created</option>
                <option value="0">Scheduled</option>
                <option value="1">Shown</option>
                <option value="2">Missed</option>
                <option value="3">Confirmed</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- lead-search -->

    <!-- leads-table-area  -->
    <div class="leads-table-area task-table">
      <div class="timeline">
        <button
          type="button"
          v-bind:class="$store.state.appointFilter == 'today' ? 'current' : ''"
          v-on:click="updateData('today')"
        >
          <span class="today">Today</span>
        </button>
        <button
          type="button"
          v-bind:class="$store.state.appointFilter == 'month' ? 'current' : ''"
          v-on:click="updateData('month')"
        >
          <span class="today">This Month</span>
        </button>
        <button
          type="button"
          v-bind:class="$store.state.appointFilter == 'custom' ? 'current' : ''"
        >
          <date-range-picker
            :opens="'left'"
            :ranges="false"
            :locale-data="{ format: 'yyyy-mm-dd' }"
            v-model="filterSearch.customDate"
            @update="updateData('custom')"
          ></date-range-picker
          ><span class="month">Custom Date</span>
        </button>
      </div>
      <table class="display" id="appt_table">
        <thead>
          <tr>
            <th>Leadsheet ID</th>
            <th>Appt Date</th>
            <th>Appt Time</th>
            <th>Customer Name</th>
            <th>In Showroom</th>
            <th>Sales Rep</th>
            <th>BDC Rep</th>
            <th>Creation Date</th>
            <th>Status</th>
            <th>Action</th>

            <!-- <th>Leadsheet #</th>
            <th>Customer Name</th>
            <th>Sales Rep</th>
            <th>BDC Rep</th>
            <th>Appt Date</th>
            <th>Appt Time</th>
            <th>Status</th> -->
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    </div>
    <!-- leads-table-area  -->

    <!-- appointment  deatils Modal -->
    <div
      class="modal fade"
      id="appointmentDetail"
      tabindex="-1"
      role="dialog"
      aria-labelledby="vehicleDetailModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <a class="delas-colse close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true"
              ><img
                class="img-fluid"
                src="../../assets/img/23. Closex.svg"
                alt=""
            /></span>
          </a>
          <div class="modal-body Intersets-modal">
            <div class="row">
              <div class="col-12">
                <div class="details-modal">
                  <table class="table">
                    <tbody>
                      <tr>
                        <td>Description</td>
                        <td v-html="appointment_detail.a_desc"></td>
                      </tr>
                      <tr>
                        <td>Date</td>
                        <td>{{ appointment_detail.a_date }}</td>
                      </tr>
                      <tr>
                        <td>Time</td>
                        <td>{{ appointment_detail.a_time }}</td>
                      </tr>
                      <tr>
                        <td>Scheduled By</td>
                        <td>{{ appointment_detail.a_by }}</td>
                      </tr>
                      <tr>
                        <td>Scheduled For</td>
                        <td>{{ appointment_detail.a_for }}</td>
                      </tr>
                      <tr>
                        <td>Status</td>
                        <td>{{ appointment_detail.a_status }}</td>
                      </tr>
                      <tr>
                        <td>Lead Id</td>
                        <td>
                          <a
                            href="#"
                            v-on:click.stop.prevent="
                              redirectIt(appointment_detail.ap_lead_id)
                            "
                          >
                            {{ appointment_detail.ap_lead_id }}
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>Created Date</td>
                        <td>{{ appointment_detail.created_date }}</td>
                      </tr>
                      <br />
                      <div class="row gotolead">
                        <a
                          href="#"
                          v-on:click.stop.prevent="
                            redirectIt(appointment_detail.ap_lead_id)
                          "
                          class="rem-btn"
                          ><span> Go to lead</span></a
                        >
                      </div>
                    </tbody>
                  </table>
                </div>
              </div>
              <!-- <div class="col-12 text-center mt-3">
                  <button class="rem-btn" style="display: initial">Select</button>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- appointment  deatils Modal  -->
    <!-- reschedule-appointments -->
    <div class="add-appointments upd-appointments">
      <div class="modal-header">
        <h5 class="modal-title">Edit Appointment</h5>
        <a
          class="close hide-appi"
          data-dismiss="modal"
          aria-label="Close"
          v-on:click="toggleUpdateAppointment('hide')"
        >
          <span aria-hidden="true"
            ><img
              class="img-fluid"
              src="../../assets/img/23. Closex.svg"
              alt=""
          /></span>
        </a>
      </div>
      <form v-on:submit.prevent="updateAppointment">
        <div class="add-body">
          <div class="form-group">
            <label for="inputAddress">Date</label>
            <date-range-picker
              :min-date="yesterdayDate"
              :locale-data="{ format: 'mmmm dd, yyyy' }"
              v-model="updateApt.ufDate"
              :single-date-picker="true"
              :show-dropdowns="true"
              :auto-apply="true"
              :ranges="false"
            ></date-range-picker>
            <!-- <input type="date" class="form-control" placeholder="Pick your date" v-model="updateApt.date"> -->
            <div
              class="validate-error-message"
              v-if="$vd.updateApt.ufDate.$hasError === true"
            >
              {{ $vd.updateApt.$errors.ufDate[0] }}
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label>Time</label>
              <vue-timepicker
                v-model="updateApt.time"
                format="h:mm A"
                name="timepicker"
                :minute-interval="5"
                placeholder="hh:mm a"
              ></vue-timepicker>
            </div>
            <div
              class="validate-error-message"
              v-if="$vd.updateApt.time.$hasError === true"
            >
              {{ $vd.updateApt.$errors.time[0] }}
            </div>
            <!-- <div class="form-group col-md-4">
                            <label for="inputCity">Time</label>
                            <select class="form-control" v-model="updateApt.tHour">
                                <option value="">hours</option>
                                <option v-for="(n, index) in 12" :key="index" v-bind:value="n">{{n}}</option>
                            </select>

                        </div>
                        <div class="form-group col-md-4">
                            <label for="inputState"></label>
                            <select class="form-control" v-model="updateApt.tMin">
                                <option value="">minutes</option>
                                <option v-for="(n, index) in 60" :key="index" v-bind:value="n-1 | numberStep('2')">{{n-1 | numberStep('2')}}</option>
                            </select>
                        </div>
                        <div class="form-group col-md-4">
                            <label for="inputZip"></label>
                            <select class="form-control" v-model="updateApt.tDtype">
                                <option value="am">am</option>
                                <option value="pm">pm</option>
                            </select>
                        </div>
                        <div class="validate-error-message" v-if="$vd.updateApt.tHour.$hasError === true || $vd.updateApt.tMin.$hasError === true || $vd.updateApt.tDtype.$hasError === true">Pleae enter correct time</div> -->
          </div>
          <div
            class="form-group"
            v-if="$storage.get('auth').user.user_type == 1"
          >
            <label for="inputAddress2">Assign to</label>
            <select class="form-control" v-model="updateApt.assignTo">
              <option
                v-for="emp in allEmployees"
                :key="emp.id"
                :value="emp.id"
              >
                {{ emp.name }}
              </option>
              <!-- <option>Brandon Wilson</option>
                            <option>Brandon Wilson</option> -->
            </select>
            <!-- <div class="validate-error-message" v-if="$vd.updateApt.assignTo.$hasError === true">{{$vd.updateApt.$errors.assignTo[0]}}</div> -->
          </div>
          <div class="form-group">
            <label for="inputAddress2"
              >Description
              <!--<span>(optional)</span>--></label
            >
            <textarea
              class="form-control"
              v-model="updateApt.desc"
              v-on:keyup="ResheduleliveCountDown"
              rows="5"
            ></textarea>
            <br>
            <p>
              Total Remaining:
              <span v-bind:class="{ 'text-danger': generateErr }">{{
                totalRemainCount
              }}</span>
            </p>
            <div
              class="validate-error-message"
              v-if="$vd.updateApt.desc.$hasError === true"
            >
              {{ $vd.updateApt.$errors.desc[0] }}
            </div>
          </div>
        </div>
        <div class="modal-footer app-footer">
          <a
            href="#"
            class="add-btn"
            v-on:click="toggleUpdateAppointment('hide')"
          >
            Cancel</a
          >
          <button :disabled="appointmentLoader" type="submit" class="rem-btn">
            <span v-if="appointmentLoader"
              ><span class="spinner-grow spinner-grow-sm"></span>
              Loading...</span
            >
            <span v-if="!appointmentLoader"
              ><img
                class="img-fluid"
                src="../../assets/img/23. Close.svg"
                alt=""
              />
              Update</span
            >
          </button>
        </div>
      </form>
    </div>
    <!-- reschedule-appointments -->
  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";
import "datatables.net";
import "datatables.net-responsive";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";
import moment from "moment";
import DeleteIcon from "../../assets/img/Delete.svg";
import EditIcon from "../../assets/img/edit.svg";
import ViewIcon from "../../assets/img/Eye.svg";
import Swal from "sweetalert2";
import VueDaval from "vue-daval";

export default {
  name: "AllAppointments",
  components: {
    DateRangePicker,
    VueTimepicker,
  },
  props: ["defaultAssignTo"],
  mixins: [VueDaval],
  data() {
    return {
      appts: null,
      limitmaxCount: 40,
      totalRemainCount: 40,
      generateErr: false,
      yesterdayDate: moment().subtract(1, "day").format("YYYY-MM-DD"),
      filterSearch: {
        duration: "month",
        agent: "",
        team: "",
        customDate: {},
        from: "",
        to: "",
        status: "",
      },
      appointment_detail: {},
      allEmployees: [],
      allTeams: [],
      updateApt: {
        id: null,
        date: "",
        ufDate: { startDate: new Date(), endDate: new Date() },
        time: "",
        assignTo: this.$props.defaultAssignTo,
        desc: "",
        page: this.$route.name,
      },
      appointmentLoader: false,
    };
  },
  watch: {
    defaultAssignTo: function (rt) {
      this.updateApt.assignTo = rt
    },
  },
  vdRules: {
    updateApt: {
      id: { required: true },
      ufDate: { required: true },
      desc: { required: true },
      time: {
        checkAlias: (alias) => {
          if (
            alias != "" &&
            alias != undefined &&
            alias != "undefined" &&
            alias != null
          ) {
            if (alias.indexOf("hh") > -1) {
              return "This time is not valid. Please select complete time.";
            } else if (alias.indexOf("mm") > -1) {
              return "This time is not valid. Please select complete time.";
            } else if (alias.indexOf("AM") > -1) {
              return true;
            } else if (alias.indexOf("PM") > -1) {
              return true;
            } else {
              return "This time is not valid. Please select complete time.";
            }
          } else {
            return true;
          }
        },
      },
    },
  },
  created() {
    if (this.$storage.get("auth").user.user_type != 2) {
      this.getAllEmployees();
      this.getAllTeams();
    }
    if (
      this.$route.query.duration != undefined &&
      this.$route.query.duration != null &&
      this.$route.query.duration != "undefined" &&
      this.$route.query.duration != ""
    ) {
      this.filterSearch.duration = this.$route.query.duration;
    }
    if (
      this.$route.query.status != undefined &&
      this.$route.query.status != null &&
      this.$route.query.status != "undefined" &&
      this.$route.query.status != ""
    ) {
      this.filterSearch.status = 0;
    }
  },
  methods: {
    liveCountDown: function () {
      this.totalRemainCount = this.limitmaxCount - this.addApt.desc.length;
      this.generateErr = this.totalRemainCount < 0;
    },
    ResheduleliveCountDown() {
      this.totalRemainCount = this.limitmaxCount - this.updateApt.desc.length;
      this.generateErr = this.totalRemainCount < 0;
    },
    updateAppointment() {
      this.appointmentLoader = true;
      if (this.totalRemainCount < 0) {
        this.$toast.open({
          position: "bottom",
          message: "Max limit is 40 characters",
          type: "info",
        });
        return false;
      }

      this.$vd.updateApt
        .$validate()
        .then(() => {
          let startDate = this.updateApt.ufDate.startDate;
          this.updateApt.date = moment(new Date(startDate)).format(
            "YYYY-MM-DD"
          );
          //console.log(this.updateApt);
          axios
            .post("update_apt", this.updateApt, {
              headers: {
                Authorization: "Bearer " + this.$storage.get("auth").token,
              },
            })
            .then((res) => {
              if (res.data.success) {
                this.appointmentLoader = false;
                this.toggleUpdateAppointment("hide");
                this.updateApt = {
                  id: null,
                  date: "",
                  ufDate: { startDate: new Date(), endDate: new Date() },
                  time: "",
                  assignTo: this.$props.defaultAssignTo,
                  desc: "",
                  page: this.$route.name,
                };
                $("#appt_table").dataTable().fnDraw();
              }
            })
            .catch((err) => {
              this.appointmentLoader = false;
              console.log(err);
            });
        })
        .catch(() => {
          this.appointmentLoader = false;
          console.log(this.$vd.addApt.$errors);
        });
    },
    reScheduleApt(apt_id) {
      axios
        .get("/appointment/get/" + apt_id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          const data = res.data;
          if (data.success) {
            let apt = data.success;
            this.updateApt.id = apt.ap_id;
            this.updateApt.desc = apt.ap_description;
            this.updateApt.assignTo = apt.ap_created_for;
            this.updateApt.ufDate.startDate = moment(apt.ap_date_time).format('MMMM DD, YYYY');
            this.updateApt.ufDate.endDate = moment(apt.ap_date_time).format('MMMM DD, YYYY');
            this.updateApt.time = moment(apt.ap_date_time).format('h:mm A');
            this.updateApt.lead_id = apt.ap_lead_id;
            this.toggleUpdateAppointment("show");
          }else{
            // $("#appointmentDetail").modal("show");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    formatAMPM(date) {
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    },
    toggleUpdateAppointment(action) {
      if (action == "show") {
        $(".upd-appointments").addClass("show-appointments");
      } else if (action == "hide") {
        $(".upd-appointments").removeClass("show-appointments");
      }
    },
    redirectIt(id) {
      $("#appointmentDetail").modal("hide");
      setTimeout(() => {
        this.$router.push("/lead-detail/" + id);
      }, 400);
    },
    apptDetail(id) {
      this.redirectIt(id);
      // axios
      //   .get("/appointment/detail/" + id, {
      //     headers: {
      //       Authorization: "Bearer " + this.$storage.get("auth").token,
      //     },
      //   })
      //   .then((res) => {
      //     const data = res.data;
      //     if (data.success) {
      //       this.appointment_detail = data.success;
      //     }
      //     $("#appointmentDetail").modal("show");
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    },
    getAllEmployees() {
      axios
        .get("all_employees", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          const data = res.data;
          if (data.success) {
            this.allEmployees = data.success;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getAllTeams() {
      axios
        .get("teams", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          this.allTeams = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateData(val) {
      if (val == "agent") {
        this.filterSearch.team = "";
      } else if (val == "team") {
        this.filterSearch.agent = "";
      } else if (val == "custom") {
        let startDate = this.filterSearch.customDate.startDate;
        let endDate = this.filterSearch.customDate.endDate;
        this.$store.state.appoint_from = moment(new Date(startDate)).format(
          "YYYY-MM-DD"
        );
        this.$store.state.appoint_to = moment(new Date(endDate)).format(
          "YYYY-MM-DD"
        );

        this.$store.commit("updateAppointFilter", "custom");
      } else if (val == "today") {
        this.$store.commit("updateAppointFilter", "today");
      } else if (val == "month") {
        this.$store.commit("updateAppointFilter", "month");
      }
      $("#appt_table").dataTable().fnDraw();
    },
    deleteAppt(id) {
      axios
        .get("delete_appt/" + id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          }
      }).then(()=>{
          $("#appt_table").dataTable().fnDraw();
      }).catch((err)=>{
          console.log(err);
      });
    }
  },
  mounted() {
    let v = this;
    if (v.$route.query.type) {
      if (v.$route.query.type == "created") {
        if (v.$route.query.duration == "custom") {
          v.filterSearch.from = v.$route.query.from;
          v.filterSearch.to = v.$route.query.to;
        } else if (v.$route.query.duration == "monthly") {
          v.filterSearch.duration = "month";
        } else {
          v.filterSearch.duration = v.$route.query.duration;
        }
        v.filterSearch.status = "all";
      }
      if (v.$route.query.type == "missed") {
        if (v.$route.query.duration == "custom") {
          v.filterSearch.from = v.$route.query.from;
          v.filterSearch.to = v.$route.query.to;
        } else if (v.$route.query.duration == "monthly") {
          v.filterSearch.duration = "month";
        } else {
          v.filterSearch.duration = v.$route.query.duration;
        }
        v.filterSearch.status = 2;
      }
      if (v.$route.query.type == "shown") {
        if (v.$route.query.duration == "custom") {
          v.filterSearch.from = v.$route.query.from;
          v.filterSearch.to = v.$route.query.to;
        } else if (v.$route.query.duration == "monthly") {
          v.filterSearch.duration = "month";
        } else {
          v.filterSearch.duration = v.$route.query.duration;
        }
        v.filterSearch.status = 1;
      }
      if (v.$route.query.type == "confirmed") {
        if (v.$route.query.duration == "custom") {
          v.filterSearch.from = v.$route.query.from;
          v.filterSearch.to = v.$route.query.to;
        } else if (v.$route.query.duration == "monthly") {
          v.filterSearch.duration = "month";
        } else {
          v.filterSearch.duration = v.$route.query.duration;
        }
        v.filterSearch.status = 3;
      }
      if (v.$route.query.type == "scheduled") {
        if (v.$route.query.duration == "custom") {
          v.filterSearch.from = v.$route.query.from;
          v.filterSearch.to = v.$route.query.to;
        } else if (v.$route.query.duration == "monthly") {
          v.filterSearch.duration = "month";
        } else {
          v.filterSearch.duration = v.$route.query.duration;
        }
        v.filterSearch.status = 0;
      }
    }
    $("#appt_table")
      .addClass("nowrap")
      .dataTable({
        responsive: true,
        fixedHeader: true,
        processing: true,
        serverSide: true,
        stateSave: true,
        order: [[1, "desc"]],
        ajax: {
          url: axios.defaults.baseURL + "appointments_list",
          type: "POST",
          data: function (d) {
            d.agent = v.filterSearch.agent;
            d.tm_id = v.filterSearch.team;
            d.duration = v.$store.state.appointFilter;
            d.from = v.$store.state.appoint_from;
            d.to = v.$store.state.appoint_to;
            d.status = v.filterSearch.status;
            //console.log(d)
          },
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        },
        columnDefs: [
          {
            targets: [3, 4, 6],
            orderable: false,
          },
          {
            targets: 9,
            orderable: false,
            render: function (data, type, row) {
              return (
                '<a href="#" class="appointment-action" data-id="' +
                row[10] +
                '" data-action="view"><img src="'+ViewIcon+'" /></a> | <a href="#" class="appointment-action" data-id="' +
                row[9] +
                '" data-action="edit"><img src="'+EditIcon+'" /></a> | <a href="#" class="appointment-action" data-id="' +
                row[9] +
                '" data-action="delete"><img src="'+DeleteIcon+'" /></a>'
              );
            },
          }
        ],
      });

    $("#appt_table").on("click", ".customer_name", function () {
      var id = $(this).data("id");
      if (id !== undefined && id != "undefined" && id != null && id != "") {
        v.apptDetail(id);
      }
    });

    $("#appt_table").on("click",".appointment-action", function () {
      //get data-id attribute of the clicked element
      var id = $(this).data("id");
      var action = $(this).data("action");
      
      console.log('id',id);
      console.log('action',action);

      if (id !== undefined && id != "undefined" && id != null && id != "") {
        switch (action) {
          case 'view':
              v.apptDetail(id);
            break;
          case 'edit':
              v.reScheduleApt(id);
            break;
          case 'delete':
              //sweet alert confirmation
              Swal.fire({
                title: "Are you sure?",
                text: "You want to delete this appointment?",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                // cancelButtonColor: "#fff",
                confirmButtonText: "Yes, delete it!",
              }).then((result) => {
                if (result.value) {
                  v.deleteAppt(id);
                }
              });
            break;
          default:
            break;
        }
      }
    });

    if (this.$route.query.type) {
      if (this.$route.query.type == "created") {
        v.filterSearch.status = "all";
      }
      if (this.$route.query.type == "missed") {
        v.filterSearch.status = 2;
      }
      if (this.$route.query.type == "shown") {
        v.filterSearch.status = 1;
      }
      if (this.$route.query.type == "confirmed") {
        v.filterSearch.status = 3;
      }
      if (this.$route.query.type == "scheduled") {
        v.filterSearch.status = 0;
      }
    }
  },
};
</script>
<style scoped>
@import "../../assets/css/dataTables.css";

.dataTables_wrapper .dataTables_info {
  margin-left: 10px;
}
.dataTables_wrapper .dataTables_paginate {
  margin-right: 10px;
}

.task-table table.dataTable {
  width: 100%!important;
  padding: 0;
}
.dataTables_wrapper .dataTables_length {
  color: #6d778e;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.5;
  text-transform: capitalize;
  margin-left: 10px;
  margin-bottom: 15px;
}

.task-table .timeline,
.addcustomer-table .timeline {
  margin-top: 103px;
  position: relative;
  top: 15px;
  float: right;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 2;
  width: 330px;
  margin: 0 auto;
}
.vue-daterange-picker {
  min-width: auto;
}
.vue__time-picker input.display-time {
  margin: 0;
  font-size: 14px !important;
  width: 100% !important;
  color: #6d778e;
  border: 1px solid #ccc !important;
  font-weight: 400;
  z-index: 2;
  background: transparent;
  cursor: pointer;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem !important;
  height: auto !important;
}
.form-group .vue-daterange-picker {
  width: 100% !important;
}
.tasks-area .vue-daterange-picker {
  width: 100% !important;
}
.vue__time-picker {
  width: 100% !important;
}
.vue__time-picker input.display-time:focus {
  border: 1px solid #ccc;
  outline: none;
  cursor: pointer;
}
.vue-daterange-picker {
  display: block !important;
}
.vue__time-picker input.display-time::-webkit-input-placeholder,
.vue__time-picker input.display-time::-ms-input-placeholder,
.vue__time-picker input.display-time::placeholder,
.vue__time-picker input.display-time:-ms-input-placeholder {
  color: #bbb;
}
</style>
